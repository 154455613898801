body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  background-color: #000 !important;
  color: #fff;
  height: 50px;
}

.fbottom {
  color: #ffffff !important;
}

.ftop {
  color: #ffffff !important;
}

/* Logo Wrapper */

.carousel {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #e5e5e5;
  padding: 80px 0px;
}

.carousel .slide img {
  width: 100px !important;
}

.carousel .control-dots .dot {
  background: #000 !important;
}

/* Client */
.carousel1{
  background: #000;
  padding: 80px 0px;
}